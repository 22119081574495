<template>
  <div class="w-full p-2 bg-iq-grey-light/30 relative">
    <div
      v-if="props.disabled"
      class="absolute top-0 right-0 w-full h-full bg-iq-grey-dark bg-opacity-80 flex items-center justify-center text-center"
    >
      <div class="z-50">
        <NuxtLink to="/login">
          <Icon
            name="heroicons:lock-closed"
            class="w-12 h-12 mb-2 text-iq-red"
          /><span class="!text-iq-red text-bold-24" to="/login">{{
            t('unlock')
          }}</span>
        </NuxtLink>
      </div>
    </div>
    <span class="text-bold-24 block w-full text-center">{{ t('title') }}</span>
    <UiLabel
      class="text-regular-16 lg:text-regular-18 !text-iq-white/60 text-center mb-4"
    >
      {{ t('subtitle') }}
    </UiLabel>
    <div
      class="flex flex-col md:flex-row gap-4 md:justify-between w-full px-4 items-start md:items-center"
    >
      <div class="flex flex-row items-center flex-1 justify-center">
        <AppCheckbox
          v-model="isCheckedCasual"
          :label="t(ModificatorLabel[SalesTextModificator.CASUAL])"
          @change:checkbox="toggle($event, SalesTextModificator.CASUAL)"
        />
      </div>
      <div class="flex flex-row items-center flex-1 justify-center">
        <AppCheckbox
          v-model="isCheckedLessVerbose"
          :label="t(ModificatorLabel[SalesTextModificator.LESS_VERBOSE])"
          @change:checkbox="toggle($event, SalesTextModificator.LESS_VERBOSE)"
        />
      </div>
      <div class="flex gap-2 items-end flex-1 justify-center">
        <AppCheckbox
          v-model="isCheckedShortened"
          :label="t(ModificatorLabel[SalesTextModificator.SHORTENED])"
          @change:checkbox="toggle($event, SalesTextModificator.SHORTENED)"
        />
      </div>
      <div class="flex gap-2 items-end flex-1 justify-center">
        <AppCheckbox
          v-model="isCheckedStronglyShortened"
          :label="t(ModificatorLabel[SalesTextModificator.STRONGLY_SHORTENED])"
          @change:checkbox="
            toggle($event, SalesTextModificator.STRONGLY_SHORTENED)
          "
        />
      </div>
    </div>
    <div
      class="flex flex-col md:flex-row gap-4 md:justify-between w-full px-4 items-start md:items-center"
    >
      <div class="flex gap-4 mt-2 items-center flex-1 justify-center">
        <div class="flex items-center gap-2">
          <AppCheckbox
            v-model="isCheckedTranslate"
            :label="t(ModificatorLabel[SalesTextModificator.TRANSLATE])"
            @change:checkbox="toggle($event, SalesTextModificator.TRANSLATE)"
          />
        </div>
        <TheLanguageSelect
          v-model="language"
          :disabled="
            !selectedModificators.includes(SalesTextModificator.TRANSLATE)
          "
        />
      </div>
    </div>
    <UiButton
      class="iq-button-success mt-4 w-full"
      :disabled="!selectedModificators.length"
      @click="emit('apply:modificators', selectedModificators, language)"
    >
      {{ t('button.apply') }}
    </UiButton>
  </div>
</template>

<script setup lang="ts">
import { Language } from '~/enum/language.enum.js';
import { ModificatorLabel } from '../enum/modificator-label.enum.js';
import { SalesTextModificator } from '../enum/sales-text-modificator.enum';

const props = defineProps<{
  defaultLanguage?: Language;
  disabled?: boolean;
}>();

const emit = defineEmits<{
  (
    event: 'apply:modificators',
    value: SalesTextModificator[],
    language: Language
  ): void;
}>();

const { t, locale } = useI18n({ useScope: 'local' });

const language = ref<Language>(
  props.defaultLanguage ??
    (locale.value.toUpperCase() as Language) ??
    Language.EN
);

const isCheckedCasual = ref(false);
const isCheckedLessVerbose = ref(false);
const isCheckedStronglyShortened = ref(false);
const isCheckedShortened = ref(false);
const isCheckedTranslate = ref(false);
const selectedModificators = ref<SalesTextModificator[]>([]);

function toggle(value: boolean, key: SalesTextModificator) {
  const isShortened = key === SalesTextModificator.SHORTENED;
  const isStronglyShortened = key === SalesTextModificator.STRONGLY_SHORTENED;

  if (value) {
    if (!selectedModificators.value.includes(key)) {
      selectedModificators.value.push(key);
    }
    if (isShortened) {
      isCheckedStronglyShortened.value = false;
    }
    if (isStronglyShortened) {
      isCheckedShortened.value = false;
    }
  } else {
    const index = selectedModificators.value.indexOf(key);
    if (index > -1) {
      selectedModificators.value.splice(index, 1);
    }
  }

  if (isShortened || isStronglyShortened) {
    const oppositeKey = isShortened
      ? SalesTextModificator.STRONGLY_SHORTENED
      : SalesTextModificator.SHORTENED;
    const indexOpposite = selectedModificators.value.indexOf(oppositeKey);
    if (indexOpposite > -1) {
      selectedModificators.value.splice(indexOpposite, 1);
    }
  }
}
</script>
<i18n lang="json">
{
  "en": {
    "button": {
      "apply": "APPLY"
    },
    "title": "Customize Cover Letter",
    "subtitle": "Here you can customize the original cover letter",
    "unlock": "Create account to unlock"
  },
  "de": {
    "button": {
      "apply": "ANWENDEN"
    },
    "title": "Anschreiben anpassen",
    "subtitle": "Hier kannst du das ursprüngliche Anschreiben nochmal anpassen",
    "unlock": "Account anlegen zum freischalten"
  }
}
</i18n>
