<template>
  <div class="flex items-center">
    <UiCheckbox
      :id="id"
      v-model:checked="model"
      @update:checked="emit('change:checkbox', $event)"
    />
    <label
      :for="id"
      class="text-regular-16 ml-2 leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70 hover:cursor-pointer"
    >
      {{ label }}
    </label>
  </div>
</template>

<script setup lang="ts">
defineProps<{ label: string }>();
const emit = defineEmits<{
  (event: 'change:checkbox', value: boolean): void;
}>();

const id = useId();

const model = defineModel({
  required: true,
  type: Boolean,
  default: () => ref(),
});
</script>
