<template>
  <UiCollapsible v-model:open="isOpen">
    <div class="flex flex-col items-center justify-between w-full">
      <UiCollapsibleTrigger as-child>
        <UiButton
          variant="ghost"
          class="p-0 flex flex-1 lg:max-w-full items-center text-left hover:!bg-transparent mx-2 w-full justify-normal"
        >
          <Icon
            class="flex-shrink-0 w-4 h-4 mr-4"
            :name="`${
              isOpen ? 'heroicons:chevron-up' : 'heroicons:chevron-down'
            }`"
          />
          <h4
            :class="{ 'pb-4': !isShownIcons }"
            class="truncate text-bold-26 lg:text-bold-34 max-w-[80%] min-w-[80%] lg:w-full overflow-visible"
          >
            {{ props.title }}
          </h4>
        </UiButton>
      </UiCollapsibleTrigger>
      <div v-if="isShownIcons" class="self-start py-3 pl-6">
        <template v-for="(icon, index) in icons" :key="index"
          ><Icon
            v-if="icon.isOpen"
            class="mx-2 icon-iq"
            :name="icon.name"
            @click="icon.click()"
          />
          </template>
      </div>
    </div>
    <transition name="collapsible">
      <UiCollapsibleContent>
        <div
          :class="`!text-iq-white text-regular-20 lg:text-regular-24 whitespace-break-spaces mt-0 my-4 pt-4 overflow-y-auto max-h-[${props.contentHeight}]`"
        >
          {{ salesTextSelected?.text }}
        </div>
        <div v-if="revisions?.length" class="flex mt-6 w-full justify-center">
          <Icon
            class="mr-2 icon-iq"
            name="heroicons:chevron-left"
            :class="
              indexSelected === 0
                ? 'opacity-50 hover:!opacity-50 hover:!cursor-not-allowed'
                : ''
            "
            @click="decreaseIndex"
          />

          <span class="text-regular-20">
            {{ t('revision.variants') }} {{ indexSelected + 1 }}/{{
              revisions?.length + 1
            }}
          </span>
          <Icon
            class="ml-2 icon-iq"
            name="heroicons:chevron-right"
            :class="
              indexSelected === revisions?.length
                ? 'opacity-50 hover:!opacity-50 hover:!cursor-not-allowed'
                : ''
            "
            @click="increaseIndex"
          />
        </div>
        <span
          v-if="indexSelected > 0"
          class="text-regular-16 mt-2 !text-iq-grey-light underline iq-hover text-center flex w-full justify-center"
          @click="indexSelected = 0"
          >{{ t('revision.regenerate') }}</span
        >
        <div
          v-if="
            salesTextSelected?.salesTextModificators?.length ||
            salesTextSelected?.language
          "
          class="flex flex-col gap-2 mt-4 mb-2"
        >
          <span class="text-regular-18 !text-iq-white/60">{{
            t('revision.applied')
          }}</span>
          <div class="flex gap-2">
            <template
              v-for="(mod, i) in salesTextSelected.salesTextModificators"
              :key="i"
            >
              <UiBadge
                v-if="mod !== SalesTextModificator.TRANSLATE"
                class="text-center md:text-left"
              >
                {{ t(ModificatorLabel[mod]) }}
              </UiBadge>
            </template>
            <UiBadge
              v-if="salesTextSelected?.language"
              class="text-center md:text-left"
            >
              {{ t(`languages.${salesTextSelected.language}`) }}
            </UiBadge>
          </div>
        </div>

        <TheSalesTextModificator
          v-if="
            props.resultTextType === ResultTextType.SALESTEXT &&
            indexSelected === 0 &&
            !isScanning
          "
          class="mt-4"
          :default-language="props.language"
          :disabled="props.disableModification"
          @apply:modificators="(value: SalesTextModificator[], language: Language) => applyModificators(value, language)"
        />
      </UiCollapsibleContent>
    </transition>
  </UiCollapsible>
</template>
<script lang="ts" setup>
import type { ResultText } from '@prisma/client';
import { useClipboard } from '@vueuse/core';
import { toast } from 'vue-sonner';
import type { Language } from '~/enum/language.enum';
import { ModificatorLabel } from '../enum/modificator-label.enum';
import { ResultTextType } from '../enum/result-text-type.enum';
import { SalesTextModificator } from '../enum/sales-text-modificator.enum';

const { t } = useI18n();

const props = defineProps<{
  contentHeight?: string;
  initOpen?: boolean;
  isScanning?: boolean;
  resultTextType?: ResultTextType;
  revisions?: Array<Partial<ResultText> | null>;
  showCopy?: boolean;
  showEmail?: boolean;
  showDownload?: boolean;
  title?: string;
  text?: string;
  language?: Language;
  disableModification?: boolean;
}>();

const emit = defineEmits<{
  (
    event: 'apply:modificators',
    value: SalesTextModificator[],
    language: Language
  ): void;
}>();

const isOpen = ref(false);
const indexSelected = ref(0);

const revisions = computed(() => {
  return props.revisions?.filter(Boolean);
});

const salesTextSelected = computed(() => {
  if (indexSelected.value === 0) {
    return { text: props.text, salesTextModificators: [] };
  }
  return props.revisions?.[indexSelected.value - 1];
});

const { copy, copied, isSupported } = useClipboard();

const isShownIcons = computed(() => {
  return props.showCopy || props.showEmail || props.showDownload;
});

const icons = [
  {
    name: 'heroicons:paper-airplane',
    isOpen: props.showEmail,
    click: () => {
      const subject = encodeURIComponent(props.title ?? '');
      const body = encodeURIComponent(salesTextSelected.value?.text ?? '');
      const mailtoLink = `mailto:?subject=${subject}&body=${body}`;

      // Create an anchor element with the mailto link
      const mailtoAnchor = document.createElement('a');
      mailtoAnchor.href = mailtoLink;
      mailtoAnchor.target = '_blank';
      trackAtServer('scan_mail', {
        section: props.title,
      });
      // Trigger a click event on the anchor element
      mailtoAnchor.click();
      toast(t('toast.email-open'));
    },
  },
  {
    name: 'heroicons:arrow-down-tray',
    isOpen: props.showDownload,
    click: async () => {
      trackAtServer('scan_download', {
        section: props.title,
      });

      const preparedTitle = `${props.title
        ?.replace('https://www.', '')
        .replace('http://www.', '')
        .substring(0, 50)}${
        props.title && props.title.length > 57 ? '...' : ''
      }`;

      if (props.showDownload) {
        (await import('../utils/pdf/pdf.utils')).createPDF(
          preparedTitle ?? '',
          salesTextSelected.value?.text ?? ''
        );
      }

      // await createPDF(preparedTitle ?? '', props.text ?? '');
    },
  },
  {
    name: 'heroicons:document-duplicate',
    isOpen: props.showCopy,
    click: async () => {
      trackAtServer('scan_copy', {
        section: props.title,
      });

      if (isSupported.value) {
        await copy(salesTextSelected.value?.text ?? '');
      }
      if (copied.value) {
        toast(`${props.title} ${t('toast.copy-success')}`);
      }
    },
  },
];

function applyModificators(values: SalesTextModificator[], language: Language) {
  emit('apply:modificators', values, language);

  const modificatorKeys = values.map((v) => {
    return `${v}: true`;
  });

  useTrackEvent('edit_salestext', modificatorKeys);
}

function decreaseIndex() {
  if (indexSelected.value > 0) {
    indexSelected.value -= 1;
  }
}
function increaseIndex() {
  if (
    props.revisions?.length &&
    indexSelected.value < props.revisions?.length - 1
  ) {
    indexSelected.value += 1;
  }
}

defineExpose({
  isOpen,
  indexSelected,
});
onBeforeMount(() => {
  if (props.initOpen) {
    isOpen.value = true;
  }
});
</script>
<style>
.collapsible-enter-active,
.collapsible-leave-active {
  transition: max-height 0.3s ease;
  overflow: hidden;
}

.collapsible-enter, .collapsible-leave-to /* .collapsible-leave-active in <2.1.8 */ {
  max-height: 0;
}
</style>
<i18n lang="json">
{
  "de": {
    "revision": {
      "applied": "Angewandte Anpassungen:",
      "regenerate": "Text nochmal anpassen",
      "variants": "Variante:"
    },
    "toast": {
      "email-open": "Mail Client wird geöffnet",
      "copy-success": "wurde in die Zwischenablage kopiert"
    },

    "languages": {
      "DE": "Deutsch",
      "EN": "Englisch",
      "ES": "Spanisch",
      "FR": "Französisch",
      "NL": "Niederländisch"
    }
  },
  "en": {
    "revision": {
      "applied": "Modificators applied:",
      "regenerate": "Regenerate text",
      "variants": "Variant:"
    },
    "toast": {
      "email-open": "Email client will be opened",
      "copy-success": "has been copied to the clipboard"
    },

    "languages": {
      "DE": "German",
      "EN": "English",
      "ES": "Spanish",
      "FR": "French",
      "NL": "Dutch"
    }
  }
}
</i18n>
