export enum SalesTextModificator {
  CASUAL = 'CASUAL', // Siezen
  FORMAL = 'FORMAL', // Dutzen
  INFORMAL = 'INFORMAL',
  LESS_VERBOSE = 'LESS_VERBOSE',
  RESPECTFUL = 'RESPECTFUL', // FORMAL couldn't be chosen here because it's also the most suitable translation for 'Siezen'
  SHORTENED = 'SHORTENED',
  STRONGLY_SHORTENED = 'STRONGLY_SHORTENED',
  VERBOSE = 'VERBOSE',
  TRANSLATE = 'TRANSLATE',
}
