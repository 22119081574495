export enum ModificatorLabel {
  CASUAL = 'LABEL.MODIFICATOR.CASUAL',
  FORMAL = 'LABEL.MODIFICATOR.FORMAL',
  INFORMAL = 'LABEL.MODIFICATOR.INFORMAL',
  LESS_VERBOSE = 'LABEL.MODIFICATOR.LESS_VERBOSE',
  RESPECTFUL = 'LABEL.MODIFICATOR.RESPECTFUL',
  SHORTENED = 'LABEL.MODIFICATOR.SHORTENED',
  STRONGLY_SHORTENED = 'LABEL.MODIFICATOR.STRONGLY_SHORTENED',
  TRANSLATE = 'LABEL.MODIFICATOR.TRANSLATE',
  VERBOSE = 'LABEL.MODIFICATOR.VERBOSE',
}
